export const reducedDIV = (divi) => {
  switch (divi) {
    case "Southeast":
      return "SE";
    case "Southwest":
      return "SW";
    case "Northwest":
      return "NW";
    case "Atlantic":
      return "Atl.";
    case "Central":
      return "Cen.";
    case "Pacific":
      return "Pac.";
    default:
      return divi;
  }
};

const offensePos = ["QB", "RB", "TE", "WR", "OT", "OG", "C"];
const defensePos = ["DE", "DT", "DL", "DB", "LB"];
const specialTeam = ["K", "P"];

export const checkPos = (newSelect, selected) => {
  if (
    (offensePos.includes(newSelect) && offensePos.includes(selected)) ||
    (defensePos.includes(newSelect) && defensePos.includes(selected)) ||
    (specialTeam.includes(newSelect) && specialTeam.includes(selected))
  ) {
    return true;
  }

  return false;
};

export const generateRandom = (total) => {
  const rand = parseInt(Math.random() * 10000);
  return rand - parseInt(rand / total) * total;
};
